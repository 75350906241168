import React from "react"
import CardItem from "./CardItem"

export default class CardsList extends React.Component {
  render() {
    return (
      <div className=" block md:grid md:grid-cols-3">
        {this.props.items.map((item, i) => (
          <CardItem
            key={i}
            item={item}
            count={i + 1}
            responsive={true}
          ></CardItem>
        ))}
      </div>
    )
  }
}
