import React from "react"
import { withTrans } from "../withTrans"

class FilterHeader extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedCheckboxes: [],
      groupTypeVisible: false,
      groupSpaceVisible: false,
    }
    this.wrapperRef1 = React.createRef()
    this.wrapperRef2 = React.createRef()

    this.handleChange = this.handleChange.bind(this)
    this.handleSpace = this.handleSpace.bind(this)
    this.handleType = this.handleType.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.toggleTypeGroup = this.toggleTypeGroup.bind(this)
    this.toggleSpaceGroup = this.toggleSpaceGroup.bind(this)
  }
  toggleTypeGroup() {
    this.setState({
      groupTypeVisible: !this.state.groupTypeVisible,
      groupSpaceVisible: false,
    })
  }
  toggleSpaceGroup() {
    this.setState({
      groupTypeVisible: false,
      groupSpaceVisible: !this.state.groupSpaceVisible,
    })
  }
  handleSpace(item) {
    const checkedBoxes = this.state.selectedCheckboxes
    //Filter the checkboxes to remove other category items from array
    checkedBoxes
      .filter(
        category => this.props.categories.typeOfService.indexOf(category) > -1
      )
      .map(foo => checkedBoxes.splice(foo, 1))
    this.handleChange(item, checkedBoxes)
  }
  handleType(item) {
    const checkedBoxes = this.state.selectedCheckboxes
    //Filter the checkboxes to remove other category items from array
    checkedBoxes
      .filter(
        category => this.props.categories.spaceServices.indexOf(category) > -1
      )
      .map(foo => checkedBoxes.splice(foo, 1))
    this.handleChange(item, checkedBoxes)
  }
  handleChange(item, checkedBoxes) {
    if (checkedBoxes.includes(item)) {
      checkedBoxes.splice(checkedBoxes.indexOf(item), 1)
    } else {
      checkedBoxes.push(item)
    }

    this.setState(
      {
        selectedCheckboxes: checkedBoxes,
      },
      () => {
        this.props.onFilterChange(this.state.selectedCheckboxes)
      }
    )
  }
  handleClickOutside = e => {
    if (
      this.wrapperRef1.current &&
      !this.wrapperRef1.current.contains(e.target)
    ) {
      this.setState({
        groupSpaceVisible: false,
      })
    }
    if (
      this.wrapperRef2.current &&
      !this.wrapperRef2.current.contains(e.target)
    ) {
      this.setState({
        groupTypeVisible: false,
      })
    }
  }
  clearFilters() {
    this.setState(
      {
        selectedCheckboxes: [],
        groupTypeVisible: false,
        groupSpaceVisible: false,
      },
      () => {
        this.props.onFilterChange(this.state.selectedCheckboxes)
      }
    )
  }
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside, false)
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside, false)
    this.wrapperRef1.current.focus()
    this.wrapperRef2.current.focus()
  }
  render() {
    const { t } = this.props
    const { selectedCheckboxes } = this.state
    return (
      <div className="font-futurastd_book flex-col lg:flex lg:flex-row lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
        <div className="bg-white uppercase w-full lg:w-auto">
          <div onClick={this.clearFilters} className="text-center">
            <span className="text-2xl">&lt;</span>
            <span className="text-base tracking-title-3 ml-3 mr-2">
              {this.props.title}
            </span>
            <span className="text-2xl">&gt;</span>
          </div>
        </div>
        <div className="bg-white pr-2 lg:order-first">
          <div className="bg-title-double-slash bg-left bg-no-repeat pl-8 leading-7">
            <button
              onClick={() => this.toggleSpaceGroup()}
              className={`${
                this.state.groupSpaceVisible
                  ? `font-futurastd_bold`
                  : `font-futurastd_book`
              } bg-white text-label-fs-10 uppercase tracking-title-2`}
            >
              {t("filter_filter_by_space")}
            </button>
          </div>
          <div
            ref={this.wrapperRef1}
            className={`${
              this.state.groupSpaceVisible
                ? `lg:absolute lg:block overflow-auto hide-scrollbar whitespace-nowrap`
                : `hidden`
            } lg:top-30 lg:bg-white lg:border-black lg:border-dashed lg:border p-2`}
          >
            {this.props.categories.spaceServices.map((item, i) => (
              <div
                className={`${
                  selectedCheckboxes.includes(item)
                    ? `bg-black text-white`
                    : `bg-white text-black`
                } text-black mx-3 border-black lg:bg-transparent border lg:border-none inline-block lg:block`}
                key={i}
              >
                <input
                  id={i}
                  className="hidden lg:inline"
                  type="checkbox"
                  onChange={() => {
                    this.handleSpace(item)
                  }}
                  name={item}
                  checked={selectedCheckboxes.includes(item)}
                />
                <label
                  htmlFor={i}
                  className={`${
                    selectedCheckboxes.includes(item)
                      ? ` text-white lg:text-black`
                      : ` text-black`
                  } p-2 font-futurastd_book uppercase text-label-fs-10`}
                >
                  {t(item)}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white pr-2">
          <div className="bg-title-double-slash bg-left bg-no-repeat pl-8 leading-7">
            <button
              onClick={() => this.toggleTypeGroup()}
              className={`${
                this.state.groupTypeVisible
                  ? `font-futurastd_bold`
                  : `font-futurastd_book`
              } bg-white text-label-fs-10 uppercase tracking-title-2`}
            >
              {t("filter_filter_by_type")}
            </button>
          </div>
          <div
            ref={this.wrapperRef2}
            className={`${
              this.state.groupTypeVisible
                ? `lg:absolute lg:block overflow-auto hide-scrollbar whitespace-nowrap`
                : `hidden`
            } lg:top-30 lg:bg-white lg:border-black lg:border-dashed lg:border p-2`}
          >
            {this.props.categories.typeOfService.map((item, i) => (
              <div
                className={`${
                  selectedCheckboxes.includes(item)
                    ? `bg-black text-white `
                    : `bg-white text-black `
                } text-black mx-3 lg:bg-transparent border-black border lg:border-none inline-block lg:block`}
                key={i}
              >
                <input
                  id={i + 10}
                  className="hidden lg:inline"
                  type="checkbox"
                  onChange={() => {
                    this.handleType(item)
                  }}
                  name={item}
                  checked={selectedCheckboxes.includes(item)}
                />
                <label
                  htmlFor={i + 10}
                  className={`${
                    selectedCheckboxes.includes(item)
                      ? ` text-white lg:text-black`
                      : ` text-black`
                  } p-2 font-futurastd_book uppercase text-label-fs-10`}
                >
                  {t(item)}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
export default withTrans(FilterHeader)
