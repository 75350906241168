import Layout from "../components/layout"
import CardsList from "../components/CardsList"
import { graphql } from "gatsby"
import FilterHeader from "../components/FilterHeader"
import React from "react"
import { withTrans } from "../withTrans"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    site {
      categories: siteMetadata {
        spaceServices
        typeOfService
      }
    }
    items: gcms {
      servicesCards(locales: $locale) {
        title
        description
        id
        cardImage(locales: en) {
          url
        }
        slug
        spaceService
        typeOfService
      }
    }
  }
`

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      servicesItems: props.data.items.servicesCards,
      filteredItems: props.data.items.servicesCards,
    }
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }

  handleFilterChange(selectedCheckboxes) {
    let temp = []
    this.state.servicesItems
      .filter(
        item =>
          selectedCheckboxes.indexOf(item.spaceService) > -1 ||
          selectedCheckboxes.indexOf(item.typeOfService) > -1
      )
      .map(filteredItem => temp.push(filteredItem))

    if (temp.length < 1) {
      temp = this.state.servicesItems
    }

    this.setState({
      filteredItems: temp,
    })
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        <FilterHeader
          title={t("navbar_title_services")}
          categories={this.props.data.site.categories}
          onFilterChange={this.handleFilterChange}
        />

        <CardsList items={this.state.filteredItems} />
      </Layout>
    )
  }
}

export default withTrans(Services)